.bio-main-title {
    margin-bottom: 30px;
    padding-bottom: 15px;
    text-align: center;
    border-bottom: 1px solid gray;
}

.bio-card-container {
    padding: 30px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    border: 1px solid grey;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 15px;
    text-indent: 30px;
}

.bio-card-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }