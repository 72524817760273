.navbar-container {
    width: 100%;
    height: 60px;
}

.navbar {
    list-style-type: none;
    width: 100%;
    height: 80%;
    padding: 0px;
    float: left;
    display: flex;
    flex-direction:row;
}

.box {
    padding-top: 10px;
    text-align: center;
    font-family: "Courier New";
    float: left;
    order: 1;
    flex-grow: 1;
}

.box-buttons {
    height: 100%;
    width: 100%;   
}
