.proj-main-title{
    margin-bottom: 30px;
    padding-bottom: 15px;
    text-align: center;
    border-bottom: 1px solid gray;
}

.project-button{
    padding: 20%;
    color: #000000;
    margin-bottom: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-button::before {
    content: "";
    background-image: var(--image);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.25;
}

.back-button {
    padding: 5px;
    background-color: #F0F8FF;
    border-radius: 12px;
}

.project-col-container {

    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;

    -webkit-column-fill: balance;
    -moz-column-fill: balance;
    column-fill: balance;

    padding-bottom: 30px;
}

.blog-left-content {
    float: left;
    width: 40%;
    height: 100%;
}

.blog-right-content {
    margin-left: 40%;
    height: 100%;
}

.blog-card {
    /* border-width: 2px;
    border: dashed; */
    min-height: 350px;
}

.blog-image {
    object-fit: contain;
    width: 400px;
    height: 300px;
}

input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
label {
padding: 12px 12px 12px 0;
display: inline-block;
}

input[type=submit] {
background-color: #4CAF50;
color: white;
padding: 12px 20px;
border: none;
border-radius: 4px;
cursor: pointer;
float: right;
}

input[type=submit]:hover {
background-color: #45a049;
}

.container {
border-radius: 5px;
background-color: #f2f2f2;
padding: 20px;
}

.col-25 {
float: left;
width: 25%;
margin-top: 6px;
}

.col-75 {
float: left;
width: 75%;
margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
content: "";
display: table;
clear: both;
}