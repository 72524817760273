.prof-main-title {
    margin-bottom: 30px;
    padding-bottom: 15px;
    text-align: center;
    border-bottom: 1px solid gray;
}

.prof-col-container {

    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 60px;

    -webkit-column-fill: initial;
    -moz-column-fill: initial;
    column-fill: balance;

    padding-bottom: 30px;
}

.prof-normal-container {
    padding-bottom: 30px;
}

.prof-title-in-container {
    column-span: all;
}
