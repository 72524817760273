.app-body {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
}
footer {
  margin-left: 0px;
  margin-right: 0px;
  position:fixed;
  bottom:0;
  width:100%;
  height:40px;   /* Height of the footer */
  background:#F0F8FF;
  text-align: right;
  font-size: smaller;
}
